import React from "react";
import RequestButton from "../RequestButton";

const HypertensionInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Hypertension Q & A
        </p>

        <p className="text-xl text-gray-800 mb-2">What is hypertension?</p>
        <p className="text-md text-gray-600 mb-2">
          Hypertension (high blood pressure) is a serious issue that can lead to
          potentially life-threatening diseases such as heart attacks, kidney
          failure, and stroke.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Blood pressure is the force pumping your blood through your arteries,
          the large blood vessels that carry oxygenated blood to your organs and
          tissues. If your blood pressure gets too high, the impact of the blood
          pumping with such force can damage your heart and other organs.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Even though hypertension is a serious problem, it doesn’t cause any
          symptoms. Until it reaches a point where you suffer a stroke or heart
          attack, you’re likely to be completely unaware there’s anything wrong.
        </p>
        <p className="text-md text-gray-600 mb-12">
          To avoid this happening, have a regular blood pressure checkup at
          Sunstate Medical Associates. This is a very simple test that can show
          whether you have hypertension, so you can get the right treatment and
          prevent potentially life-threatening events.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          How is blood pressure measured?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Although hypertension can be a serious issue, measuring your blood
          pressure is easy.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Your provider at Sunstate Medical Associates wraps a cuff around your
          arm that inflates to exert pressure on your arteries. One reading
          records the pressure as your heart beats, which is called the systolic
          pressure. The other reading records the pressure between heartbeats,
          which is called the diastolic pressure.
        </p>
        <p className="text-md text-gray-600 mb-2">
          When combined, these two figures represent your blood pressure
          measurement. A healthy reading would be systolic pressure below 120,
          and diastolic pressure below 80. Raised blood pressure would be a
          reading between 121 and 140 systolic, or between 81 and 90 diastolic.
          At this stage you should take action to reduce your blood pressure
          before it develops into hypertension.
        </p>
        <p className="text-md text-gray-600 mb-12">
          A measurement above 140 systolic or over 90 diastolic indicates stage
          1 hypertension. Stage 2 hypertension, where your blood pressure is 160
          or above systolic or 100 or above diastolic, puts you in immediate
          danger of a potentially life-threatening illness.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          How is hypertension treated?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Treatment for your hypertension depends on the cause. You could have
          secondary hypertension, which develops as a result of other conditions
          like diabetes or kidney disease. In these cases, treating the
          underlying cause and taking blood pressure medication when necessary
          should keep it under control.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Primary hypertension is typically brought on by poor lifestyle
          choices, so addressing these can make a significant impact on reducing
          blood pressure. Steps you can take include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Losing weight</li>
          <li>Eating a low-fat diet</li>
          <li>Cutting down on salt</li>
          <li>Exercising regularly</li>
          <li>Managing chronic stress </li>
          <li>Quitting smoking</li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          The Sunstate Medical Associates team can advise and support you
          through these changes. You might also need to take blood pressure
          pills for severe hypertension.
        </p>
        <p className="text-md text-gray-600 mb-10">
          To arrange your blood pressure screening, call Sunstate Medical
          Associates today or book an appointment online.
        </p>

        <RequestButton />
      </div>
    </div>
  );
};

export default HypertensionInfo;
