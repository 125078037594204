import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import HypertensionInfo from "../../components/services/HypertensionInfo";

const Hypertension = () => {
  return (
    <Layout>
      <PageHeader text="Hypertension" />
      <ServicesHeader>
        Hypertension (high blood pressure) is something many people have without
        realizing, as it causes no symptoms. Without treatment, hypertension can
        be deadly, so it’s vital to get your blood pressure checked regularly at
        Sunstate Medical Associates in Lake Mary, Florida. The board-certified
        medical team can help you reduce your blood pressure and regain your
        health before you experience a stroke or heart attack. Call Sunstate
        Medical Associates today to arrange a blood pressure check or use the
        convenient online form.
      </ServicesHeader>
      <HypertensionInfo />
      <Conditions />
    </Layout>
  );
};

export default Hypertension;
